body {
    font-family: 'Open Sans', 'Trebuchet MS', sans-serif;
    font-size: 18px;
    min-height: 100vh;
}

a {
    color: #4472c4;
    text-decoration: none;
}

a:hover {
    text-decoration: underline;
}

a:visited {
    color: #4472c4;
    text-decoration: none;
}

.App {
    text-align: center;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    background-color: #aaa;
}

.App-logo {
    width: 32vmin;
    pointer-events: none;
}

.App-header {
    background-color: #282c34;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-body {
    display: flex;
    width: 100%;
    max-width: 420px;
    justify-content: center;
}

::placeholder {
    color: #bbb;
    font-style: italic;
    font-size: 0.8em;
}
